<template>
  <div class="login-content d-flex flex-column p-10 overflow-hidden animate__animated animate__fadeIn">
    <!--begin::Signup-->
    <div class="login-form login-signin">
      <!--begin::Logo-->
      <div v-if="$vuetify.breakpoint.mobile" class="text-center mb-10">
        <a href="#" class="flex-column-auto">
          <img src="/media/logos/logo-with-name-black-vertical.png" width="60%" />
        </a>
      </div>

      <div class="text-center mb-sm-10">
        <h3 class="font-weight-bolder text-dark display5">Crie sua conta</h3>
        <p class="text-muted font-weight-bold font-size-h6">Entre com os dados abaixo para realizar seu cadastro.</p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <!-- Error message-->
        <!-- <div v-for="(value, name) in errors" key="">{{ name }}: {{ value }}</div> -->
        <div v-for="(item, index) in errors" :key="index" role="alert" class="alert show alert-danger">
          <div class="alert-text">
            <i class="flaticon-warning text-white"></i>
            {{ item[0] }}
          </div>
        </div>

        <!-- Input FullName -->
        <b-form-group label="Nome Completo" label-class="font-size-h6 font-weight-bolder text-dark">
          <b-form-input
            v-model="$v.form.name.$model"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            name="example-input-0"
            :state="validateState('name')"
          />

          <b-form-invalid-feedback>Nome completo obrigatório.</b-form-invalid-feedback>
        </b-form-group>

        <!-- Input Type Document -->
        <b-form-group label="Tipo" label-class="font-size-h6 font-weight-bolder text-dark">
          <b-form-radio-group
            id="btn-radios-2"
            v-model="form.document_type.selected"
            :options="form.document_type.options"
            buttons
            button-variant="outline-primary"
            size="lg"
            name="radio-btn-outline"
          ></b-form-radio-group>
        </b-form-group>

        <!-- Input Number Document -->
        <b-form-group
          :label="form.document_type.selected == 1 ? 'CPF' : 'CNPJ'"
          label-class="font-size-h6 font-weight-bolder text-dark"
        >
          <b-form-input
            v-model="$v.form.code.$model"
            v-mask="form.document_type.selected === '1' ? ['###.###.###-##'] : '##.###.###/####-##'"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('code')"
          />
          <b-form-invalid-feedback>{{ validationMsg($v.form.code) }}</b-form-invalid-feedback>
        </b-form-group>

        <!-- Input Email -->
        <b-form-group label="Email" label-class="font-size-h6 font-weight-bolder text-dark">
          <b-form-input
            v-model="$v.form.email.$model"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            name="example-input-0"
            :state="validateState('email')"
          />

          <b-form-invalid-feedback>{{ validationMsg($v.form.email) }}</b-form-invalid-feedback>
        </b-form-group>

        <!-- Input Password -->
        <b-form-group label="Senha" label-class="font-size-h6 font-weight-bolder text-dark">
          <b-form-input
            v-model="$v.form.password.$model"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            type="password"
            name="example-input-2"
            :state="validateState('password')"
          ></b-form-input>

          <b-form-invalid-feedback v-if="!$v.form.password.required">Senha é obrigatória.</b-form-invalid-feedback>

          <b-form-invalid-feedback v-if="!$v.form.password.minLength">
            A senha deve conter no mínimo 8 caracteres.
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Input Re-Password -->
        <b-form-group label="Confirmação de senha" label-class="font-size-h6 font-weight-bolder text-dark">
          <b-form-input
            v-model="$v.form.password_confirmation.$model"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            type="password"
            name="example-input-2"
            :state="validateState('password_confirmation')"
          ></b-form-input>

          <b-form-invalid-feedback v-if="!$v.form.password_confirmation.required">
            Senha é obrigatória.
          </b-form-invalid-feedback>

          <b-form-invalid-feedback v-if="!$v.form.password_confirmation.sameAsPassword">
            As senhas não coincidem.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action Register-->
        <div class="form-group d-flex flex-wrap flex-center">
          <button
            ref="kt_login_signup_submit"
            type="submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
          >
            Quero ser Snail
          </button>
        </div>

        <!--begin::Action Login-->
        <div class="form-group d-flex flex-wrap flex-center">
          <span class="font-weight-bold font-size-3 text-dark-60">Já tem uma conta?</span>
          <router-link custom class="font-weight-bold font-size-3 ml-2" :to="{ name: 'login' }">
            Entre agora!
          </router-link>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signup-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from 'vuex';
import { REGISTER, LOGOUT } from '@/core/services/store/modules/auth';
import { validationMixin } from 'vuelidate';
import { email, required, minLength, sameAs } from 'vuelidate/lib/validators';
import { validationMessage } from 'vuelidate-messages';
import formMessages from '@/core/utils/validationMessages';
import { mask } from 'vue-the-mask';
import validationCpfCnpj from '@/core/utils/validationCpfCnpj';
import debounceAsyncValidator from '@/core/utils/validationDeBouce';

export default {
  name: 'Register',
  directives: { mask },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        fullname: null,
        document_type: {
          selected: '1',
          options: [
            { text: 'Pessoa Fisíca', value: '1' },
            { text: 'Pessoa Jurídica', value: '2' }
          ]
        },
        code: '',
        email: '',
        password: '',
        password_confirmation: ''
      }
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors
    })
  },
  validations: {
    form: {
      name: {
        required
      },
      document_type: {
        selected: {
          required
        }
      },
      code: {
        required,
        validateFormatCpfCnpj(value) {
          return validationCpfCnpj(value);
        },
        isUniqueCpfCnpj: debounceAsyncValidator(function (value, debounce) {
          // synchronous validations
          if (!value) return true;
          // capture all component state synchronously
          return debounce()
            .then(() => this.$store.dispatch('CHECK_IS_UNIQUE_CPF_CNPJ', { code: value }))
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
        }, 1000)
      },
      email: {
        required,
        email,
        isUniqueEmail: debounceAsyncValidator(function (value, debounce) {
          // synchronous validations
          if (!value) return true;
          // capture all component state synchronously
          return debounce()
            .then(() => this.$store.dispatch('CHECK_IS_UNIQUE_EMAIL', { email: value }))
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
        }, 1000)
      },
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  watch: {
    'form.document_type.selected': function () {
      this.form.code = '';
    },
    deep: true
  },
  created() {
    this.$store.commit('RESET_ERROR');
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        fullname: null,
        typecode: null,
        code: null,
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    validationMsg: validationMessage(formMessages),
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs['kt_login_signup_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // send register request
      await this.$store
        .dispatch(REGISTER, {
          name: this.$v.form.name.$model,
          type: this.$v.form.document_type.selected.$model,
          code: this.$v.form.code.$model.replace(/[^0-9]/g, ''),
          email: this.$v.form.email.$model,
          password: this.$v.form.password.$model,
          password_confirmation: this.$v.form.password_confirmation.$model
        })
        .then(() => {
          this.$swal({
            title: 'Cadastro realizado com sucesso!',
            text: 'Parabéns a partir de agora você é um Snail.Enviamos um email para confirmação do seu cadastro, não esqueça de verificar sua caixa de spam.',
            icon: 'success',
            button: 'Entrar no meu dashboard'
          }).then(() => this.$router.push({ name: 'dashboard' }));
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        })
        .catch(() => {
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        });
    }
  }
};
</script>
